const Tag = ({ width }) => {
    return (
        <div className={`tag-icon ${width}`}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88.03 88.03">
                <g fillRule="currentColor">
                    <path d="M85.9 0c-.13 0-.25.03-.38.06L45.85 2.97c-.48.04-.93.24-1.27.58L.59 47.37c-.78.78-.78 2.05 0 2.83l37.39 37.24c.78.78 2.04.78 2.82 0l44-43.83c.34-.34.55-.8.58-1.28.88-13.29 1.71-26.57 2.59-39.85a2.01 2.01 0 00-.47-1.8c-.03-.03-.06-.07-.1-.1-.04-.04-.08-.07-.12-.11-.39-.33-.88-.5-1.39-.47zm-2.05 4.19c-.82 12.38-1.59 24.76-2.41 37.13L39.39 83.21 4.83 48.79 46.88 6.9l36.97-2.71z"></path>
                    <path d="M68.78 13.35c-1.59 0-3.18.6-4.39 1.79s-.01.01-.02.02c-2.44 2.38-2.44 6.37 0 8.75l.02.02c2.42 2.39 6.38 2.38 8.8-.02 2.41-2.4 2.41-6.35 0-8.75a6.213 6.213 0 00-4.41-1.81zm0 3.96c.57 0 1.13.23 1.59.68.81.81.81 2.28 0 3.09-.91.91-2.27.91-3.18 0l-.02-.02c-.86-.82-.86-2.21 0-3.04l.02-.02c.46-.45 1.02-.68 1.59-.68z"></path>
                </g>
            </svg>
        </div>
    )
}

export default Tag
