import React, { useState, useEffect, useMemo, Fragment } from 'react'
import qs from 'query-string'
import { useSwipeable } from 'react-swipeable'
import { useStore } from '@nanostores/react'
import { isCartOpen, cartData } from '@stores/cartStore'
import { checkoutData } from '@stores/checkoutStore'
import {
    createCart,
    updateCart,
    updateCartDiscountCodes,
    updateCartNote,
    updateCartAttributes,
    addToCart,
    getCart,
} from '@api/shopify'

import { chord } from '@api/chord.js'
import va from '@vercel/analytics'

import {
    addUrlParameter,
    setCookie,
    getCookie,
    splitLastOccurrence,
} from '@utils/utils'

import Gift from '@components/Icons/Gift.jsx'
import ThickX from '@components/Icons/ThickX.jsx'
import ShoppingBag from '@components/Icons/ShoppingBag.jsx'
import Tag from '@components/Icons/Tag.jsx'
import FreeProduct from '@components/Cart/FreeProduct.jsx'
import YourBabyOffer from '@components/Cart/YourBabyOffer.jsx'
import Recommended from '@components/Recommendations/Recommended.jsx'

import {
    handleQuantityChange,
    handleRemoveDiscount,
    handleCheckout,
    manageDiscounts,
    handleRemoveItem,
    // handleNoteSubmit,
    updateCartTrackingAttributes,
} from './cartUtility.jsx'

const ShippingRemainder = React.lazy(() =>
    import('@components/Cart/ShippingRemainder.jsx')
)

const ybCampaigns = ['ybct2', 'ybct3', 'ybc0-3', 'ybc3-6', 'ybpage']

const invalidProductTypes = [
    'Gift Cards',
    'Gift Card',
    'Specialty SKU',
    'Embroidery Option',
    'Embroidery',
    'Virtual Classes',
    'Classes + Events',
    'Nursery',
    'Cribs',
    'Classes',
    'Strollers',
]

const Cart = () => {
    let $isCartOpen = useStore(isCartOpen || false)
    // console.log('$isCartOpen here', $isCartOpen)
    let $cartData = useStore(cartData || null)
    let $checkoutData = useStore(checkoutData || null)

    let cartId = $cartData.cartId || null

    const [cartStoreData, setCartStoreData] = useState()

    const [discount, setDiscount] = useState('')
    const [cartViewTracked, setCartViewTracked] = useState(false)
    const [data, setData] = useState({})
    const [cartTotals, setCartTotals] = useState({})
    const [loaded, setLoaded] = useState(false)
    const [discountAmount, setDiscountAmount] = useState(0)
    // const [subtotalBeforeDiscount, setSubtotalBeforeDiscount] = useState(0);
    // const [giftWrap, setGiftWrap] = useState(
    // 	$cartData && $cartData.giftWrap && $cartData.giftWrap !== "undefined"
    // 		? $cartData.giftWrap
    // 		: false
    // );

    const [giftWrapCheck, setGiftWrapCheck] = useState(false)
    const [isGiftNoteOpen, setIsGiftNoteOpen] = useState(false)
    const [discountMessage, setDiscountMessage] = useState('')
    const [giftNote, setGiftNote] = useState($cartData ? $cartData.note : '')

    const [isYourBabyOffer, setIsYourBabyOffer] = useState(false)
    const [checkoutClicked, setCheckoutClicked] = useState(false)

    let parsed
    let campaign

    let lines = $cartData?.lines || null

    let hasGiftCardInCart = lines?.some(
        (line) =>
            line.node?.merchandise?.id ===
                'gid://shopify/ProductVariant/41447180697662' ||
            line.node?.merchandise?.product?.productType === 'Gift Cards'
    )

    if (hasGiftCardInCart) {
        // Wait a brief moment for DOM to update
        setTimeout(() => {
            const cartItems = document.querySelectorAll('.cart-items .item')

            cartItems.forEach((item) => {
                const productLink = item.querySelector('.product-title > a')
                if (
                    productLink?.href?.includes(
                        '/products/gift-card?variant=41447180697662'
                    )
                ) {
                    // Find and remove quantity controls
                    const quantityControls =
                        item.querySelector('.item-quantity')
                    if (quantityControls) {
                        quantityControls.style.display = 'none'
                    }
                }
            })
        }, 100)
    }

    useEffect(() => {
        const yourBabyOffer = getCookie('isYourBabyOffer')
        //

        if (yourBabyOffer) {
            setIsYourBabyOffer(true)
        } else {
            setIsYourBabyOffer(false)
            parsed = qs.parse(window.location.search)
            //

            if (parsed && parsed.utm_campaign) {
                campaign = parsed.utm_campaign

                if (ybCampaigns.includes(campaign)) {
                    setIsYourBabyOffer(true)
                    setCookie('isYourBabyOffer', true, 7)
                }
            }
        }

        // console.log('in Cart.jsx')
    }, [])

    const timeOptions = {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZoneName: 'short',
    }

    let items = []
    let cost
    let subTotal
    let linesTotal
    let linesTotalDiscount
    let numberOfEmbroideredItems
    let embroideryTotal
    let subtotalBeforeEmbroidery
    let totalAmount
    let total
    let subtotalAmount
    let discounts
    let automaticDiscountCodes
    let shippingRemainder
    let freeProduct

    let discountedAmount
    let subtotalBeforeD

    let allClasses = false

    const handlCartAddToCart = async (e) => {
        let cartId = $cartData.cartId
        let item = e?.item
        let variantId = item?.item_url?.split('variant=')[1]
        if (!variantId) return
        variantId = e.selectedOption ? e.selectedOption : variantId

        const product = `gid://shopify/ProductVariant/${variantId}`
        let lines

        lines = {
            quantity: 1,
            merchandiseId: product,
        }

        //

        addToCart(cartId, lines).then((addToCartData) => {
            //

            let cartLines = addToCartData.body?.cartLinesAdd?.cart?.lines?.edges
            let cartCost = addToCartData.body?.cartLinesAdd?.cart?.cost

            let cartObj = {
                ...cartData.get(),
                lines: cartLines,
                cost: cartCost,
            }

            cartData.set(cartObj)
            // setIsLoading(false)
            isCartOpen.set('is_cart_open', true)
            // clearOptions()

            // if (sizes.length > 0) {
            // 		setCurrentSize({
            // 				size: '',
            // 				variantId: '',
            // 				sku: '',
            // 		})
            // } else {
            // 		setCurrentSize({
            // 				size: 'nosize',
            // 				variantId:
            // 						initialVariant &&
            // 						initialVariant[0] &&
            // 						initialVariant[0].id,
            // 				sku:
            // 						initialVariant &&
            // 						initialVariant[0] &&
            // 						initialVariant[0].sku,
            // 		})
            // }
            // setQuantityDisabled(false)

            // dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
            // dataLayer.push({
            // 		event: 'dl_add_to_cart',
            // 		page: 'pdp',
            // 		ecommerce: {
            // 				currency: 'USD',
            // 				value: currentPrice?.price,
            // 				items: [
            // 						{
            // 								item_id: variantId,
            // 								item_name: productTitle,
            // 								affiliation: 'Vercel',
            // 								item_brand: 'Monica + Andy',
            // 								item_category: currentColor ? currentColor : '',
            // 								item_category2: currentSize?.size,
            // 								item_variant: currentSize?.variantId,
            // 								price: currentPrice?.price,
            // 								quantity: quantity,
            // 						},
            // 				],
            // 		},
            // })

            // va.track('PDP Add To Cart', {
            // 		productName: productTitle,
            // 		productSku: currentSize?.sku,
            // 		productPrice: currentPrice?.price,
            // 		productQuantity: quantity,
            // 		productVariant: currentSize?.variantId,
            // 		productColor: currentColor ? currentColor : '',
            // 		productSize: currentSize?.size,
            // })
        })
    }

    useEffect(() => {
        async function updateCartAttr() {
            let giftAttr = cartData.get().attributes?.filter((a) => {
                return a.key === 'addGiftWrap'
            })
            let result = await updateCartTrackingAttributes(cartId, giftAttr)
            //
            let note = result?.body?.cartAttributesUpdate?.cart?.note
            let attributes =
                result?.body?.cartAttributesUpdate?.cart?.attributes
            let cartObj = {
                ...cartData.get(),
                note: note,
                attributes: attributes,
            }

            attributes?.forEach((a) => {
                if (a.key === 'addGiftWrap') {
                    setGiftWrapCheck(a.value === 'true')
                }
            })
            setGiftNote(note)
            cartData.set(cartObj)
            setCartStoreData(cartObj)

            setCartViewTracked(true)
        }
        if ($isCartOpen && $cartData && !cartViewTracked) {
            chord.trackCartViewed({ cart: $cartData })
            updateCartAttr()
        } else if (!$isCartOpen && cartViewTracked) {
            setCartViewTracked(false)
        }
    }, [$cartData, $isCartOpen, cartViewTracked])

    useEffect(() => {
        if ($cartData) {
            // console.log('cartData top of useEffect in Cart.jsx', $cartData)

            subtotalAmount = $cartData.cost?.subtotalAmount?.amount
            totalAmount = $cartData.cost?.totalAmount?.amount

            let items = $cartData.lines

            let subTotal
            let subtotal
            let shippingRemainder
            let applicableLines
            let discountCodes = $cartData.discountCodes
            let embroideryCodeActive = false

            //

            // let personalizeIt = discountCodes?.filter((code) => {
            //     return code.code === 'PERSONALIZEIT' || code.code === 'personalizeit' || code.code === 'Personalizeit'
            // })

            //

            // if (personalizeIt && personalizeIt.length > 0) {
            //     embroideryCodeActive = true
            // }

            if (items && items.length > 0) {
                linesTotal = items.reduce((acc, item) => {
                    let costPerItem = item.node.cost.subtotalAmount.amount
                    return acc + parseFloat(costPerItem)
                }, 0)

                applicableLines = items.filter((line) => {
                    const item = line.node
                    const productType = item?.merchandise?.product?.productType
                    const vendor = item?.merchandise?.product?.vendor
                    return (
                        !invalidProductTypes.includes(productType) &&
                        vendor === 'Monica + Andy'
                    )
                })

                if (applicableLines && applicableLines.length > 0) {
                    subtotal = applicableLines.reduce(function (
                        previousValue,
                        currentValue
                    ) {
                        //
                        return (
                            parseFloat(previousValue) +
                            parseFloat(
                                currentValue.node.cost.totalAmount.amount
                            )
                        )
                    },
                    0)
                }

                if (subtotal) {
                    subTotal = parseFloat(subtotal).toFixed(2) || 0
                    freeProduct = parseFloat(75 - subTotal).toFixed(2) || 0
                }

                //

                let $newCartData = cartData.get()

                setData({
                    ...$newCartData,
                    subTotal: subTotal,
                    freeProduct: freeProduct,
                })

                // numberOfEmbroideredItems = items.filter((item) => {
                //     return (
                //         item.node.merchandise.product.title.indexOf(
                //             'Embroidery Option'
                //         ) > -1
                //     )
                // }).length

                numberOfEmbroideredItems = items.reduce((acc, item) => {
                    if (
                        item.node.merchandise.product.title.indexOf(
                            'Embroidery Option'
                        ) > -1
                    ) {
                        let quantityPerItem = item.node.quantity
                        return acc + parseFloat(quantityPerItem)
                    }
                    return acc
                }, 0)

                //

                if (numberOfEmbroideredItems > 0) {
                    embroideryTotal = parseFloat(
                        numberOfEmbroideredItems * 15
                    ).toFixed(2)
                    subtotalBeforeEmbroidery = parseFloat(
                        linesTotal - embroideryTotal
                    ).toFixed(2)
                }

                //

                const check = 'Classes + Events'

                allClasses = items.every(
                    (item) =>
                        item.node?.merchandise?.product?.productType === check
                )

                //
            }

            if (
                subtotalAmount &&
                totalAmount &&
                subtotalAmount !== totalAmount
            ) {
                discountedAmount = totalAmount - subtotalAmount
                subtotalBeforeD = totalAmount
                total = subtotalAmount
                setCartTotals({
                    subtotal: parseFloat(subtotalAmount).toFixed(2),
                    total: totalAmount,
                    linesTotal: parseFloat(linesTotal).toFixed(2),
                    linesTotalDiscount: linesTotalDiscount,
                    discountedTotal: linesTotal - linesTotalDiscount,
                    discount: discountedAmount,
                    embroideryTotal: embroideryTotal,
                    subtotalBeforeEmbroidery: subtotalBeforeEmbroidery,
                })
            } else {
                setCartTotals({
                    subtotal: parseFloat(subtotalAmount).toFixed(2),
                    total: totalAmount,
                    linesTotal: parseFloat(linesTotal).toFixed(2),
                    linesTotalDiscount: linesTotalDiscount,
                    discountedTotal: linesTotal - linesTotalDiscount,
                    discount: 0,
                    embroideryTotal: embroideryTotal,
                    subtotalBeforeEmbroidery: subtotalBeforeEmbroidery,
                })
            }
            // setGiftNote($cartData ? $cartData.giftNote : '')
            setDiscountAmount(discountedAmount)
        }

        // setGiftWrap($cartData.giftWrap ?? false)

        setGiftNote($cartData?.note)
        if ($cartData.attributes) {
            $cartData?.attributes.forEach((a) => {
                if (a.key === 'addGiftWrap') {
                    setGiftWrapCheck(a.value === 'true')
                }
            })
        }
        setCartStoreData($cartData)

        // console.log('cartData in Cart.jsx', $cartData)
    }, [$cartData])

    useEffect(() => {
        if ($checkoutData && $checkoutData.checkout === true) {
            getCart(cartId).then((getCartData) => {
                if (getCartData && getCartData?.body?.cart !== null) {
                    let cartLines = getCartData?.body?.cart?.lines?.edges
                    let cartCost = getCartData?.body?.cart?.cost
                    let discountCodes = getCartData?.body?.cart?.discountCodes
                    let note = getCartData?.body?.cart?.note
                    let attributes = getCartData?.body?.cart?.attributes
                    // // console.log cartLines, cartCost', cartLines, cartCost)

                    let cartObj = {
                        ...cartData.get(),
                        lines: cartLines,
                        cost: cartCost,
                        discountCodes: discountCodes,
                        note: note,
                        attributes: attributes,
                    }

                    cartData.set(cartObj)
                    setCartStoreData(cartObj)
                } else {
                    //
                    cartData.set({})
                    setCartStoreData({})
                }
            })
        }
    }, [$checkoutData])

    useEffect(() => {
        if ($cartData && $cartData.lines?.length > 0 && $isCartOpen) {
            let items = $cartData.lines
            let totalAmount = $cartData.cost?.totalAmount?.amount
            let gtmItems = items.map((item) => {
                //
                let product = item.node.merchandise.product
                let productQuantity = item.node.quantity
                let options = item.node.merchandise.selectedOptions
                let id = item.node.merchandise.id
                let price = item.node.cost.subtotalAmount.amount

                id = id.split('/').pop()

                return {
                    item_id: id,
                    item_name: product.title,
                    affiliation: 'Vercel',
                    item_brand: product.vendor,
                    item_category: options[0]?.value || '',
                    item_category2: options[1]?.value || '',
                    item_variant: item?.node?.merchandise?.title
                        ? item?.node?.merchandise?.title
                        : '',
                    price: price,
                    quantity: productQuantity ? productQuantity : 1,
                }
            })

            dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
            dataLayer.push({
                event: 'dl_view_cart',
                ecommerce: {
                    items: gtmItems,
                    currency: 'USD',
                    value: totalAmount,
                },
            })
        }
    }, [$cartData, $isCartOpen])

    useEffect(() => {
        console.log('hasGiftCardInCart', hasGiftCardInCart)
    }, [])

    const handleDiscountInput = (e) => {
        setDiscount(e.target.value)
    }

    const handleApplyDiscount = async () => {
        if (discount) {
            let discountHandle = await manageDiscounts(cartId, discount)
            setDiscountMessage(discountHandle)
            setDiscount('')
        }

        setTimeout(() => {
            setDiscountMessage('')
        }, 5000)
    }

    const handleGiftWrapCheck = async (e) => {
        let checked = e.target.checked
        if (typeof checked === 'string') {
            checked = checked === 'true'
        }
        //
        // LOGIC TO CLEAR THE NOTE IF UNCHECK
        // if (!checked) {
        // 	//
        // 	setGiftNote("");
        // 	setIsGiftNoteOpen(false);
        // 	await handleNoteSubmit($cartData.cartId, "");
        // }
        // LOGIC FOR CHECK AND UNCHECK OF GIFT WRAP
        // ALSO SETTING THE SAME IN ATTRIBUTES
        setGiftWrapCheck(checked)
        let attributes = cartData.get()?.attributes || []
        const newAttribute = { key: 'addGiftWrap', value: checked.toString() }
        // Find the index of the existing "addGiftWrap" attribute
        const existingIndex = attributes.findIndex(
            (attr) => attr.key === 'addGiftWrap'
        )
        if (existingIndex !== -1) {
            // If found, update the existing attribute
            attributes[existingIndex] = newAttribute
        } else {
            // If not found, add the new attribute
            attributes.push(newAttribute)
        }

        await updateCartAttributes(cartId, attributes).then((res) => {
            // LOGIC TO CLEAR THE NOTE IF UNCHECK
            if (!checked) {
                //
                setGiftNote('')
                setIsGiftNoteOpen(false)
                handleNoteSubmit($cartData.cartId, '')
            }
        })

        //
        let cartObj = {
            ...cartData.get(),
            attributes: attributes,
        }
        cartData.set(cartObj)
        setCartStoreData(cartObj)
    }
    //

    const handleGiftNote = (e) => {
        let giftNoteText = e.target.value

        //
        if (!giftNoteText.trim()) {
            giftNoteText = ''
        }
        setGiftNote(giftNoteText)
    }

    const nullFuction = () => {}

    const handleNoteSubmit = (cartId, note) => {
        updateCartNote(cartId, note).then((res) => {
            //
            let cartNote = res?.body?.cartNoteUpdate?.cart?.note
            let cartObj = {
                ...cartData.get(),
                note: cartNote,
            }
            cartData.set(cartObj)
            setCartStoreData(cartObj)
        })
    }

    const handleSwiped = (e) => {
        //

        if (e.dir === 'Right') {
            isCartOpen.set(false)
        }
    }

    const handlers = useSwipeable({
        onSwiped: handleSwiped,
    })

    const handleCheckoutClick = () => {
        setCheckoutClicked(true)

        setTimeout(() => {
            setCheckoutClicked(false)
        }, 2000)
    }

    // console.log('isCartOpen in Cart', isCartOpen)

    // useEffect(() => {
    //     const cartItems = document.querySelectorAll('.cart-items .item')

    //     cartItems.forEach((item) => {
    //         const variantId = item.getAttribute('data-variant-id')
    //         if (variantId === '41447180697662') {
    //             item.querySelector('.item-quantity').style.display = 'none'
    //         }
    //     })
    // }, [$cartData])

    return (
        <aside
            {...handlers}
            id='cart-drawer'
            className={`cart-drawer h-full overflow-y-auto bg-white ${
                $isCartOpen ? 'translate-x-0' : 'translate-x-[100%]'
            } fixed top-0 z-50 right-0 ease-in-out duration-300 overflow-x-hidden`}
        >
            <div
                className={`cart-outer w-full max-w-[100vw] lg:max-w-[450px] lg:w-[450px] bg-white h-full min-h-[600px] ${
                    $isCartOpen ? 'cart-open' : ''
                }`}
            >
                <div className='cart-inner relative'>
                    <span
                        className='close-x text-4xl font-normal text-ash/70 absolute top-0 right-3 cursor-pointer hover:text-ash z-50 inline-block'
                        tabIndex='0'
                        onClick={() => isCartOpen.set(false)}
                    >
                        &times;
                    </span>
                    <div className='cart-header-outer'>
                        <div className='cart-header-inner flex items-center justify-center text-center p-3'>
                            <div className='cart-header-icon mr-2'>
                                <ShoppingBag width='w-[21px]' />
                            </div>
                            <h2>Shopping Cart</h2>
                        </div>
                        <ShippingRemainder />

                    </div>

                    <div className='cart-body-outer'>
                        <div className='cart-body-inner p-3'>
                            <div className='cart-body-header flex flex-col mb-4'>
                                {cartStoreData &&
                                cartStoreData.lines &&
                                cartStoreData.lines.length > 0 ? (
                                    <div className='cart-discount flex my-2 w-full'>
                                        <label
                                            htmlFor='discount-input'
                                            className='flex flex-col w-full'
                                        >
                                            Discount Code
                                            <input
                                                className='bg-slate/50 border border-slate-200 px-2 h-8 w-[96%] mr-2'
                                                type='text'
                                                id='discount-input'
                                                value={discount}
                                                onChange={(e) =>
                                                    handleDiscountInput(e)
                                                }
                                            />
                                        </label>
                                        <button
                                            className='h-8 flex items-center justify-center bg-flint text-white w-[100px] relative top-[24px] text-sm hover:bg-ash ml-3'
                                            onClick={handleApplyDiscount}
                                        >
                                            Apply
                                        </button>
                                    </div>
                                ) : null}
                                {discountMessage &&
                                discountMessage.length > 0 ? (
                                    <span className='text-red-600 text-normal text-[12px]'>
                                        {discountMessage}
                                    </span>
                                ) : null}
                                <div className='discount-codes-wrapper flex gap-1 mt-2 mb-4'>
                                    {cartStoreData &&
                                    cartStoreData.discountCodes &&
                                    cartStoreData.discountCodes.length > 0 ? (
                                        <div className='discount-codes flex'>
                                            {cartStoreData.discountCodes.map(
                                                (discount, i) => {
                                                    //
                                                    if (discount.applicable) {
                                                        return (
                                                            <span
                                                                className='discount-code py-1 px-2 bg-slate text-flint border-2 border-dashed border-oat text-xs flex items-center justify-start'
                                                                key={
                                                                    discount.code ||
                                                                    i
                                                                }
                                                            >
                                                                <span className='tag-wrap relative mr-1'>
                                                                    <Tag width='w-[12px]' />
                                                                </span>
                                                                {discount.code}{' '}
                                                                <span
                                                                    className='ml-2 cursor-pointer hover:text-red-500 text-lg leading-none relative -top-[1px] remove-discount'
                                                                    title='Remove discount'
                                                                    onClick={() =>
                                                                        handleRemoveDiscount(
                                                                            cartId,
                                                                            [
                                                                                ...cartData.get()
                                                                                    .discountCodes,
                                                                            ],
                                                                            discount.code
                                                                        )
                                                                    }
                                                                >
                                                                    &times;
                                                                </span>
                                                            </span>
                                                        )
                                                    }
                                                }
                                            )}
                                        </div>
                                    ) : null}
                                </div>
                                {cartStoreData &&
                                    cartStoreData.cost &&
                                    cartStoreData.cost.subtotalAmount &&
                                    cartStoreData.cost.subtotalAmount.amount !==
                                        '0.0' && (
                                        <Fragment>
                                            {cartTotals.linesTotal &&
                                            cartTotals.linesTotal > 0 ? (
                                                <div className='subtotal-line flex justify-between mt-1'>
                                                    <span className='cart-subtotal'>
                                                        {cartTotals.subtotalBeforeEmbroidery &&
                                                        cartTotals.subtotalBeforeEmbroidery >
                                                            0
                                                            ? 'Subtotal Before Embroidery:'
                                                            : 'Subtotal:'}
                                                    </span>{' '}
                                                    <span className='subtotal-amount'>
                                                        $
                                                        {cartTotals.subtotalBeforeEmbroidery &&
                                                        cartTotals.subtotalBeforeEmbroidery >
                                                            0
                                                            ? cartTotals.subtotalBeforeEmbroidery
                                                            : parseFloat(
                                                                  cartTotals.linesTotal
                                                              ).toFixed(2)}
                                                    </span>
                                                </div>
                                            ) : null}
                                            {cartTotals.embroideryTotal &&
                                            cartTotals.embroideryTotal >= 0 ? (
                                                <div className='subtotal-line flex justify-between mt-1'>
                                                    <span className='cart-subtotal'>
                                                        Embroidery:
                                                    </span>{' '}
                                                    <span className='subtotal-amount'>
                                                        $
                                                        {parseFloat(
                                                            cartTotals.embroideryTotal
                                                        ).toFixed(2)}
                                                    </span>
                                                </div>
                                            ) : null}
                                            {cartTotals.subtotalBeforeEmbroidery &&
                                            cartTotals.subtotalBeforeEmbroidery >=
                                                0 ? (
                                                <div className='subtotal-line flex justify-between mt-1'>
                                                    <span className='cart-subtotal'>
                                                        Subtotal:
                                                    </span>{' '}
                                                    <span className='subtotal-amount '>
                                                        ${' '}
                                                        {parseFloat(
                                                            cartTotals.linesTotal
                                                        ).toFixed(2)}
                                                    </span>
                                                </div>
                                            ) : null}
                                            {cartTotals.linesTotal !==
                                            cartTotals.subtotal ? (
                                                <div className='discount-line flex justify-between mt-1'>
                                                    <span className='cart-discount'>
                                                        Discount:
                                                    </span>{' '}
                                                    <span className='discount-amount text-flint'>
                                                        -$
                                                        {parseFloat(
                                                            cartTotals.linesTotal -
                                                                cartTotals.subtotal
                                                        ).toFixed(2)}
                                                    </span>
                                                </div>
                                            ) : null}
                                            {cartTotals.linesTotal !==
                                            cartTotals.subtotal ? (
                                                <div className='total-line flex justify-between mt-1 mb-4'>
                                                    <span className='cart-subtotal'>
                                                        Total:
                                                    </span>{' '}
                                                    <span className='subtotal-amount'>
                                                        $
                                                        {parseFloat(
                                                            cartTotals.subtotal
                                                        ).toFixed(2)}
                                                    </span>
                                                </div>
                                            ) : null}
                                        </Fragment>
                                    )}
                                {cartStoreData &&
                                    cartStoreData.lines &&
                                    cartStoreData.lines.length > 0 && (
                                        <Fragment>
                                            <button
                                                id='checkout-button'
                                                className={`focus:border-2 border-blue-400 bg-black text-white h-[42px] -full text-center flex items-center justify-center hover:bg-gray-800 mt-3 button-checkout ${
                                                    checkoutClicked
                                                        ? 'disabled cursor-not-allowed opacity-60'
                                                        : ''
                                                }`}
                                                onClick={() => {
                                                    handleCheckout(
                                                        cartStoreData.checkoutUrl
                                                    )
                                                    let checkoutObj = {
                                                        checkout: true,
                                                    }
                                                    checkoutData.set(
                                                        checkoutObj
                                                    )
                                                    handleCheckoutClick()
                                                }}
                                            >
                                                {checkoutClicked
                                                    ? 'Checking Out...'
                                                    : 'Checkout'}
                                            </button>
                                            <div className='continue-shopping mt-2 flex items-center justify-center text-center'>
                                                <span
                                                    className='text-xs underline'
                                                    onClick={() =>
                                                        isCartOpen.set(false)
                                                    }
                                                >
                                                    Continue Shopping
                                                </span>
                                            </div>
                                            {allClasses === false ? (
                                                <div className='gift-wrap-outer p-3 bg-ocean/50 mt-3'>
                                                    <div className='gift-wrap-inner flex flex-col relative'>
                                                        <label
                                                            htmlFor='gift-wrap'
                                                            className='flex items-center'
                                                        >
                                                            <input
                                                                className='inline-block mr-2'
                                                                id='gift-wrap'
                                                                type='checkbox'
                                                                onChange={(
                                                                    e
                                                                ) => {
                                                                    handleGiftWrapCheck(
                                                                        e
                                                                    )
                                                                }}
                                                                checked={
                                                                    giftWrapCheck
                                                                }
                                                            />
                                                            <span className='gift-icon w-4 block mr-2'>
                                                                <Gift width='w-4' />
                                                            </span>
                                                            <span className='gift-text inline-block'>
                                                                Add FREE Gift
                                                                Wrap
                                                            </span>
                                                        </label>
                                                        {giftWrapCheck && (
                                                            <span
                                                                className='gift-note inline-block ml-auto text-xs underline cursor-pointer absolute right-1 top-1'
                                                                onClick={() =>
                                                                    setIsGiftNoteOpen(
                                                                        !isGiftNoteOpen
                                                                    )
                                                                }
                                                            >
                                                                {isGiftNoteOpen &&
                                                                cartStoreData.note &&
                                                                cartStoreData.note !==
                                                                    ''
                                                                    ? 'Hide Gift Note'
                                                                    : `${
                                                                          cartStoreData.note &&
                                                                          cartStoreData.note !==
                                                                              ''
                                                                              ? 'Edit'
                                                                              : 'Add'
                                                                      } Gift Note`}
                                                            </span>
                                                        )}
                                                        {isGiftNoteOpen && (
                                                            <div className='gift-wrap-note'>
                                                                <p className='text-sm my-1 text-stone-700'>
                                                                    Gift Note
                                                                    (optional):
                                                                </p>
                                                                <textarea
                                                                    maxLength='140'
                                                                    placeholder='Add a note for your gift'
                                                                    className='bg-stone-100 border border-stone-200 rounded-sm px-2 h-24 w-full flex flex-start p-2 mb-3'
                                                                    onChange={(
                                                                        e
                                                                    ) =>
                                                                        handleGiftNote(
                                                                            e
                                                                        )
                                                                    }
                                                                    value={
                                                                        giftNote
                                                                    }
                                                                />
                                                                <button
                                                                    className={`w-full h-[42px] text-center flex items-center justify-center hover:bg-ash  hover:text-white ${
                                                                        !cartStoreData.note ||
                                                                        cartStoreData.note ===
                                                                            ''
                                                                            ? 'disabled cursor-not-allowed bg-slate'
                                                                            : 'bg-flint text-white'
                                                                    }`}
                                                                    onClick={() => {
                                                                        handleNoteSubmit(
                                                                            cartId,
                                                                            giftNote
                                                                        )
                                                                        setIsGiftNoteOpen(
                                                                            false
                                                                        )
                                                                    }}
                                                                >
                                                                    {cartStoreData.note ||
                                                                    cartStoreData.note !==
                                                                        ''
                                                                        ? 'Save Gift Note'
                                                                        : 'Add Gift Note'}
                                                                </button>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            ) : null}
                                        </Fragment>
                                    )}{' '}
                            </div>
                            {cartStoreData &&
                            cartStoreData.lines &&
                            cartStoreData.lines.length > 0 ? (
                                <ul className='cart-items h-content'>
                                    {cartStoreData.lines.map((item, i) => {
                                        // console.log('item in Cart.jsx', item)

                                        let variantId = item.node.merchandise.id
                                        let handle =
                                            item.node?.merchandise?.product
                                                ?.handle
                                        let variantIdNumber = variantId
                                            .split('/')
                                            .pop()



                                        let productType =
                                            item.node.merchandise.product
                                                .productType
                                        let productUrl

                                        if (
                                            variantIdNumber &&
                                            productType !== 'Gift Cards'
                                        ) {
                                            productUrl = `/products/${
                                                handle.split('_')[0]
                                            }?variant=${variantIdNumber}`
                                        } else {
                                            productUrl = `/products/${
                                                handle.split('_')[0]
                                            }`
                                        }
                                        let product =
                                            item.node.merchandise.product
                                        let productQuantity = item.node.quantity
                                        let options =
                                            item.node.merchandise
                                                .selectedOptions
                                        let attributes =
                                            item.node.attributes &&
                                            item.node.attributes.length > 0
                                                ? item.node.attributes
                                                : null
                                        let hasEmbroidery =
                                            item.node.attributes &&
                                            item.node.attributes[0] &&
                                            item.node.attributes[0].key ===
                                                'Add Embroidery?' &&
                                            item.node.attributes[0].value.indexOf(
                                                'Yes'
                                            ) > -1
                                                ? true
                                                : false
                                        let subtotalAmount =
                                            item.node?.cost?.subtotalAmount
                                                ?.amount
                                        let totalAmount =
                                            item.node?.cost?.totalAmount?.amount
                                        let discountedAmount =
                                            subtotalAmount - totalAmount
                                        let discounts =
                                            item.node.discountAllocations
                                        let isEventClass =
                                            product.tags.includes('class-event')
                                        {
                                            /*  */
                                        }
                                        let isFreeProduct =
                                            product.title.indexOf(
                                                'Free Organic Lounge Robe'
                                            ) > -1 ||
                                            product.title.indexOf(
                                                'Free Maternity'
                                            ) > -1 ||
                                            product.title.indexOf(
                                                'Free Organic'
                                            ) > -1
                                                ? true
                                                : false

                                        {
                                            /*  */
                                        }
                                        return (
                                            <Fragment
                                                key={product.id + '_' + i}
                                            >
                                                {product.title.indexOf(
                                                    'Embroidery Option'
                                                ) === -1 ? (
                                                    <li
                                                        key={
                                                            product.id + '_' + i
                                                        }
                                                        data-variant-id={variantIdNumber}
                                                        className='item flex py-2 border-t border-slate/50 flex-col !before:border-0 !after:border-0 '
                                                    >
                                                        <div className='item-wrap flex items-center'>
                                                            <span
                                                                className='item-remove cursor-pointer w-8 flex items-center justify-center text-oat hover:text-flint'
                                                                onClick={() =>
                                                                    handleRemoveItem(
                                                                        cartId,
                                                                        item
                                                                            .node
                                                                            .id,
                                                                        item
                                                                            .node
                                                                            .quantity,
                                                                        hasEmbroidery,
                                                                        variantId
                                                                    )
                                                                }
                                                            >
                                                                <ThickX width='w-[10px]' />
                                                            </span>
                                                            {product.featuredImage ? (
                                                                <div className='item-image w-[48px] mr-2'>
                                                                    <img
                                                                        src={
                                                                            product
                                                                                .featuredImage
                                                                                .url
                                                                        }
                                                                        alt={
                                                                            product
                                                                                .featuredImage
                                                                                .altText
                                                                        }
                                                                    />
                                                                </div>
                                                            ) : null}
                                                            <div className='product-info text-xs w-[200px] flex flex-col'>
                                                                <span className='product-title'>
                                                                    <a
                                                                        className='underline'
                                                                        href={
                                                                            productUrl
                                                                        }
                                                                    >
                                                                        {
                                                                            product.title.split(
                                                                                '_'
                                                                            )[0]
                                                                        }
                                                                    </a>
                                                                </span>
                                                                <span className='product-options text-xs'>
                                                                    {options &&
                                                                        options.map(
                                                                            (
                                                                                o,
                                                                                i
                                                                            ) => {
                                                                                //
                                                                                if (
                                                                                    o.name !==
                                                                                        'Gender' &&
                                                                                    o.value !==
                                                                                        'Default Title'
                                                                                ) {
                                                                                    if (
                                                                                        i <
                                                                                            options.length -
                                                                                                1 &&
                                                                                        i <
                                                                                            options.length -
                                                                                                2
                                                                                    ) {
                                                                                        return (
                                                                                            <span
                                                                                                className='mr-1'
                                                                                                key={
                                                                                                    o.value
                                                                                                }
                                                                                            >
                                                                                                {o.value +
                                                                                                    '-' +
                                                                                                    i}
                                                                                                {
                                                                                                    ' / '
                                                                                                }
                                                                                            </span>
                                                                                        )
                                                                                    } else {
                                                                                        return (
                                                                                            <span
                                                                                                className='mr-1'
                                                                                                key={
                                                                                                    o.value +
                                                                                                    '-' +
                                                                                                    i
                                                                                                }
                                                                                            >
                                                                                                {
                                                                                                    o.value
                                                                                                }
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                }
                                                                            }
                                                                        )}
                                                                </span>
                                                            </div>
                                                            {!isFreeProduct ? (
                                                                <span className={`item-quantity`}>
                                                                    <span
                                                                        className={`quantity-decrement p-2 border-slate-100 bg-slate-50 ${
                                                                            hasEmbroidery ||
                                                                            isEventClass
                                                                                ? 'disabled pointer-events-none opacity-30 cursor-not-allowed'
                                                                                : 'cursor-pointer'
                                                                        }`}
                                                                        onClick={() => {
                                                                            if (
                                                                                isEventClass
                                                                            ) {
                                                                                nullFuction()
                                                                            } else {
                                                                                handleQuantityChange(
                                                                                    cartId,
                                                                                    item
                                                                                        .node
                                                                                        .id,
                                                                                    item
                                                                                        .node
                                                                                        .quantity,
                                                                                    'decrement'
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        -
                                                                    </span>
                                                                    <span className='quanity-amount bg-white borde-slate-100'>
                                                                        {
                                                                            productQuantity
                                                                        }
                                                                    </span>
                                                                    <span
                                                                        className={`quantity-increment p-2 border-slate-100 bg-slate-50 ${
                                                                            hasEmbroidery ||
                                                                            isEventClass
                                                                                ? 'disabled pointer-events-none opacity-30 cursor-not-allowed'
                                                                                : 'cursor-pointer'
                                                                        }`}
                                                                        onClick={() => {
                                                                            if (
                                                                                isEventClass
                                                                            ) {
                                                                                nullFuction()
                                                                            } else {
                                                                                handleQuantityChange(
                                                                                    cartId,
                                                                                    item
                                                                                        .node
                                                                                        .id,
                                                                                    item
                                                                                        .node
                                                                                        .quantity,
                                                                                    'increment'
                                                                                )
                                                                            }
                                                                        }}
                                                                    >
                                                                        +
                                                                    </span>
                                                                </span>
                                                            ) : null}

                                                            <span className='product-price text-xs ml-auto'>
                                                                {subtotalAmount &&
                                                                subtotalAmount !==
                                                                    totalAmount ? (
                                                                    <span className='item-subtotal text-red-400 line-through mr-1'>
                                                                        ($
                                                                        {parseFloat(
                                                                            subtotalAmount
                                                                        ).toFixed(
                                                                            2
                                                                        )}
                                                                        )
                                                                    </span>
                                                                ) : null}
                                                                <span className='item-total'>
                                                                    $
                                                                    {parseFloat(
                                                                        totalAmount
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                            </span>
                                                        </div>

                                                        {attributes &&
                                                        attributes.length >
                                                            0 ? (
                                                            <div
                                                                className={`product-attributes`}
                                                            >
                                                                <ul className='attributes-list'>
                                                                    {attributes.map(
                                                                        (
                                                                            a,
                                                                            i
                                                                        ) => {
                                                                            //
                                                                            return a.key !==
                                                                                '_registry_item_id' &&
                                                                                a.key !==
                                                                                    '_pid' ? (
                                                                                <li
                                                                                    key={
                                                                                        a.key +
                                                                                        '_' +
                                                                                        i
                                                                                    }
                                                                                    className='product-attribute'
                                                                                >
                                                                                    <span className='attribute-key text-xs text-flint'>
                                                                                        {
                                                                                            a.key
                                                                                        }

                                                                                        :
                                                                                    </span>
                                                                                    <span className='attribute-value text-xs text-flint'>
                                                                                        {' '}
                                                                                        {
                                                                                            a.value
                                                                                        }
                                                                                    </span>
                                                                                </li>
                                                                            ) : null
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        ) : null}

                                                        {discounts &&
                                                        discounts.length > 0 ? (
                                                            <div
                                                                className={`product-discounts mt-2 ${
                                                                    hasEmbroidery
                                                                        ? ''
                                                                        : 'ml-[82px]'
                                                                }`}
                                                            >
                                                                <ul className='discounts-list'>
                                                                    {discounts.map(
                                                                        (d) => {
                                                                            return (
                                                                                <li
                                                                                    key={
                                                                                        d.title
                                                                                    }
                                                                                    className='discount-item text-[10px] text-flint'
                                                                                >
                                                                                    Discount:{' '}
                                                                                    <span className='discount-title   italic'>
                                                                                        {d.title
                                                                                            ? d.title
                                                                                            : d.code
                                                                                            ? d.code
                                                                                            : ''}
                                                                                    </span>{' '}
                                                                                    applied
                                                                                </li>
                                                                            )
                                                                        }
                                                                    )}
                                                                </ul>
                                                            </div>
                                                        ) : null}
                                                    </li>
                                                ) : null}
                                            </Fragment>
                                        )
                                    })}
                                </ul>
                            ) : (
                                <p className='text-sm text-center mx-auto'>
                                    Nothing in your cart
                                </p>
                            )}{' '}
                        </div>
                        {isYourBabyOffer ? (
                            <div className='yourbaby-offer'>
                                <YourBabyOffer />
                            </div>
                        ) : null}
                        {cartStoreData &&
                        cartStoreData.lines &&
                        cartStoreData.lines.length > 0 ? (
                            <div className='cart-upsell-wrap mt-[11px] pt-3 bg-oat/30'>
                                <Recommended
                                    productIds={cartStoreData.lines.map(
                                        (item) => {
                                            let productId =
                                                item.node.merchandise.product.id
                                            const [idBefore, idAfter] =
                                                splitLastOccurrence(
                                                    productId,
                                                    '/'
                                                )
                                            return Number(idAfter)
                                        }
                                    )}
                                    title="More Items You'll Love"
                                    type='upsell'
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </aside>
    )
}

export default Cart
